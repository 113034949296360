import { scroller } from 'react-scroll'

export default target => {
  scroller.scrollTo(target, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    spy: true,
    // offset: -35
    offset: 0
  })
}
