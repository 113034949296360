import React from 'react'
import Styled from '@emotion/styled'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import breakpoint from '../../utils/breakpoint'
import logo2 from '../../images/logo2.png'

import Text from '../Text'
import ProjectItem from './Projectitem'

export default () => {
  return (
    <Style>
      <div id='projects' className='wrapper'>
        <div className='head'>
          <img className='logo2' src={logo2} alt='' />
          <br />
          <div className='text'>
            <Text tid='ourPrjectSectionTitle' />
          </div>
        </div>
        <div className='box-projects'>
          <ProjectItem
            tag='new'
            title='Hi Seacon Station'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.36 ล้านบาท',
                en: '1.36  million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 400 เมตร BTS สายสีเหลือง (ปากซอย ศรีนครินทร์ 42)',
                en: 'Near BTS yellow line (Srinakarin 42)'
              }
            ]}
            url='https://hicondo9.com/hiseacon/'
            images={[
              require('../../images/seacon-station/Seacon-01.jpg'),
              require('../../images/seacon-station/Seacon-02.jpg'),
              require('../../images/seacon-station/Seacon-03.jpg'),
              require('../../images/seacon-station/Seacon-04.jpg')
            ]}
          />
          <ProjectItem
            tag='new'
            title='Hi Chaengwattana 19'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 999,000 บาท',
                en: '999 Thousand THB'
              },
              {
                icon: 'train',
                th: 'ใกล้รถไฟฟ้าสายสีชมพู และCentral แจ้งวัฒนะ',
                en: 'Near BTS Pink line and Chaengwattana Central'
              }
            ]}
            url='https://www.hicondo19.com/'
            images={[
              require('../../images/cwt/cwt-01.jpg'),
              require('../../images/cwt/cwt-02.jpg'),
              require('../../images/cwt/cwt-03.jpg'),
              require('../../images/cwt/cwt-04.jpg')
            ]}
          />
          <ProjectItem
            tag='new'
            title='Hi Sukhumvit 93'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.46 ล้านบาท',
                en: '1.46 Million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 4 นาที จาก BTS บางจาก - อ่อนนุช 24 ',
                en: '4 minutes from BTS Bang Chak-On-Nut 24'
              }
            ]}
            url='https://home.hicondo93.com/'
            images={[
              require('../../images/sukhumvit/sukhumvit-01.jpg'),
              require('../../images/sukhumvit/sukhumvit-02.jpg'),
              require('../../images/sukhumvit/sukhumvit-03.jpg'),
              require('../../images/sukhumvit/sukhumvit-04.jpg')
            ]}
          />
          <ProjectItem
            tag='new'
            title='Hi lardprao 130'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.59 ล้านบาท',
                en: '1.59 Million THB'
              },
              {
                icon: 'train',
                th: 'ใกล้ รถไฟฟ้า สายสีเหลือง-ส้ม และท่าเรือ',
                en: 'Near MRT yellow-orange line and Port'
              }
            ]}
            url='https://www.home.hicondo130.com/'
            images={[
              require('../../images/ladprao130/ladprao-01.jpg'),
              require('../../images/ladprao130/ladprao-02.jpg'),
              require('../../images/ladprao130/ladprao-03.jpg'),
              require('../../images/ladprao130/ladprao-04.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Sutthisan'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.48 ล้านบาท',
                en: '1.48 Million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 5 นาที จาก MRT สุทธิสาร',
                en: '5 minutes from MRT Sutthisan'
              }
            ]}
            url='https://www.hicondo8.com/'
            images={[
              require('../../images/sutthisan/sutthisan-01.jpg'),
              require('../../images/sutthisan/sutthisan-02.jpg'),
              require('../../images/sutthisan/sutthisan-03.jpg'),
              require('../../images/sutthisan/sutthisan-04.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Sea Dream'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.87 ล้านบาท',
                en: '1.87 Million THB'
              },
              {
                icon: 'privilege',
                th: 'หาดส่วนตัว เงียบสงบ',
                en: 'Privacy and Peaceful'
              }
            ]}
            url='https://www.seadreamcondo.com/'
            images={[
              require('../../images/seadream/seadream-01.jpg'),
              require('../../images/seadream/seadream-02.jpg'),
              require('../../images/seadream/seadream-03.jpg')
            ]}
          />
        </div>
      </div>
    </Style>
  )
}

const Style = Styled.div`
  label: ProjectSection;
  background: #fff;
  padding-top: 0;
  padding-left: 140px;
  padding-right: 40px;
  border-left: 10px solid #344465;

  &::before {
    content: '.';
    width: 0px;
    overflow: hidden;
    opacity: 0;
  }

  .wrapper {
    position: relative;
    background: #fff;
    padding: 45px;
    margin-top: -82px;
  }

  .head {
    text-align: right;
    margin-bottom: 60px;

    > .logo2 {
      display: inline-block;
      width: 60px;
    }
    
    > .text {
      margin-top: 10px;
      font-weight: normal;
      font-size: 20px;
    }
  }

  .box-projects {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  
  .project-item {
    width: 33.33%;
    padding: 12px;
    margin-bottom: 50px;

    .slick-list {
      overflow: hidden;
      border-bottom-left-radius: 15px;

      img {
        display: block !important;
        width: 100%;
        outline: none;
      }
    }

    > .title {
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 10px;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }
    
    > p {
      font-family: "SukhumvitSet";
      font-weight: normal;
    }

    .read-more {
      margin-top: 10px;
      display: inline-block;
      border-bottom: 2px solid #000;
      font-weight: bold;
      cursor: pointer;
    }
  }

  ${breakpoint('T')} {
    border-left: none;
    padding-left: 40px;
    padding-right: 40px;

    .wrapper {
      padding: 40px 0;
    }

    .head {
      text-align: center;
    }
  }

  ${breakpoint('M')} {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;

    .head {
      margin-bottom: 30px;
    }

    .project-item {
      width: 50%;
      margin-bottom: 20px;
    }    
  }

  ${breakpoint('540px')} {
    .box-projects {
      margin: 0 -8px;
    }

    .project-item {
      padding: 8px;

      > .title {
        font-size: 14px;
      }
    }
  }
`
