import { css } from '@emotion/core'

export default css`
  html,
  body {
    font-family: 'SukhumvitSet', sans-serif;
    background: #000514;
    color: #000000;
    font-size: 13px;
  }

  b,
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  * {
    box-sizing: border-box;
  }

  p {
    line-height: 1.5;
    margin-top: 5px;
  }

  a {
    color: #242424;
  }

  img {
    display: block;
  }
`
