import React, { useContext } from 'react'
import Styled from '@emotion/styled'
import { useInView } from 'react-hook-inview'

import breakpoint from '../../utils/breakpoint'
import aboutUsLogo from '../../images/about-us-logo.png'
import aboutUsLogoEN from '../../images/about-us-logo-en.png'
import aboutUsImg from '../../images/about-us-img.jpg'
import Text from '../Text'
import { LanguageContext } from '../../languages'

export default () => {
  const { userLanguage } = useContext(LanguageContext)

  const [ref1, text1InView] = useInView({
    threshold: 0
  })

  const [ref2, text2InView] = useInView({
    threshold: 0
  })

  return (
    <Style id='about-us'>
      {userLanguage === 'th' ? (
        <img className='logo' src={aboutUsLogo} alt='' />
      ) : (
        <img className='logo' src={aboutUsLogoEN} alt='' />
      )}
      <div className={`box-about-us-img`}>
        <img src={aboutUsImg} alt='' />
      </div>
      <div ref={ref1} className={`about-info-1 ${text1InView && 'in-view'}`}>
        <Text tid='aboutInfo1' />
      </div>
      <div ref={ref2} className={`about-info-2 ${text2InView && 'in-view'}`}>
        <Text tid='aboutInfo2' />
      </div>
    </Style>
  )
}

const Style = Styled.div`
  label: AboutUsSection;

  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 160px;
  padding-bottom: 260px;
  padding-left: 180px;
  border-left: 10px solid #344465;
  font-family: 'SukhumvitSet';
  font-weight: normal;
  font-size: 14px;

  > .logo {
    display: block;
    width: 140px;
  }

  > .box-about-us-img {
    flex-grow: 1;
    margin-left: 140px;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  > .about-info-1 {
    position: absolute;
    top: 110px;
    right: 0;
    padding: 100px 210px 40px 40px;
    background-color: rgba(255,255,255,0.7);
    border-bottom-left-radius: 15px;
    width: 620px;
    line-height: 1.61;
    opacity: 0;
    transition: all 1s linear;

    &.in-view {
      opacity: 1;
    }
  }

  > .about-info-2 {
    position: absolute;
    bottom: 210px;
    left: 0;
    padding: 40px;
    padding-left: 310px;
    background-color: rgba(255,255,255,0.7);
    width: 820px;
    line-height: 1.61;

    opacity: 0;
    transition: all 1s linear;

    &.in-view {
      opacity: 1;
    }
  }

  ${breakpoint('L')} {
    > .about-info-1 {
      top: 80px;
      width: 420px;
      padding: 40px 40px;
    }

    > .about-info-2 {
      bottom: 110px;
    }
  }

  ${breakpoint('T')} {
    display: block;
    padding: 100px 0 120px;
    border-left: none;

    > .logo {
      display: block;
      width: 100px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    > .box-about-us-img {
      margin: 0;
    }

    > .about-info-1 {
      position: relative;
      width: auto;
      margin-left: 40px;
      top: -40px;
    }

    > .about-info-2 {
      position: relative;
      width: auto;
      margin-right: 40px;
      padding: 40px;
      bottom: 0;
    }
  }

  ${breakpoint('M')} {
    padding: 40px 0 120px;

    > .logo {
      width: 60px;
    }
  }
`
