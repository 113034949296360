import React, { useState, useEffect, forwardRef } from 'react'
import Styled from '@emotion/styled'

export default forwardRef(({ name, placeholder, file1Ref }, ref) => {
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (ref.state) {
      ref.state.current = {
        clear: () => {
          setFile(null)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Style>
      <div className='filename'>{file ? file.name : placeholder}</div>
      <input
        type='file'
        ref={ref.input}
        id={name}
        name={name}
        accept='.jpg, .png, .pdf'
        onChange={(e) => setFile(e.target.files[0])}
      />
      <label htmlFor={name}>Browse</label>
    </Style>
  )
})

const Style = Styled.div`
  label: InputFile;

  display: flex;
  text-align: left;

  > label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: #000;
    border-bottom-left-radius: 10px;
    cursor: pointer;
  }

  > .filename {
    flex-grow: 1;
    padding: 10px;
    color: #666;
    border-bottom-left-radius: 10px;
    background: #fff;
    margin-right: 10px;
  }

  > input {
    display: none;
  }
`
