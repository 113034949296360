import React, { useContext } from 'react'
import Styled from '@emotion/styled'

import breakpoint from '../../utils/breakpoint'
import footerLogo from '../../images/footer-logo.png'
import footerLogo2 from '../../images/footer-logo2.png'
import footerLeft from '../../images/footer-left.png'
import footerRight from '../../images/footer-right.png'
import googleMap from '../../images/location-icon.png'
import SocialMedia from '../SocialMedia'

import { LanguageContext } from '../../languages'

export default () => {
  const { userLanguage } = useContext(LanguageContext)

  return (
    <Style id='contact-us'>
      <div className='footer-left'>
        <img src={footerLeft} alt='' />
      </div>
      <div className='footer-right'>
        <img src={footerRight} alt='' />
      </div>
      <div className='wrapper'>
        <div className='box-contact-info'>
          <img src={footerLogo} width='60px' alt='' />
          {/* <div className='title'>Shall we get in touch</div> */}
          <br />
          <br />
          {userLanguage === 'en' && (
            <p className='address'>
              Utility Real Estate Co.,ltd. <br />
              227/5 Prasert-Manukitch 29, Chorakhe Bua, Lardprao, Bangkok 10230
            </p>
          )}
          {userLanguage === 'th' && (
            <p className='address'>
              บริษัท ยูทีลิตี้ เรียล เอสเตท จำกัด <br />
              227/5 ซ.ประเสริฐมนูกิจ29 แขวงจรเข้บัว เขตลาดพร้าว กรุงเทพ 10230
            </p>
          )}
          <br />
          <p>
            <b className='tel'>TEL: 02-000-5151</b>
          </p>
          <br />
          <br />
          <a href='https://goo.gl/maps/obLQpHBWM8GN6bbM9'>
            <img src={googleMap} width='40px' alt='' />
          </a>
          <br />
          <div className='box-social-footer'>
            <SocialMedia />
          </div>
          {/* <br />
          <br />
          <br /> */}
          {/* <b>WE BUY LAND</b> */}
          {/* <div className='project-item'>HI SEACON</div>
          <div className='project-item'>HI CHAENGWATTANA 19</div>
          <div className='project-item'>HI SUKHUMVIT 93</div> */}
          <br />
          <br />
          <br />
          <img src={footerLogo2} width='100px' alt='' />
        </div>
      </div>
      <div className='bg'></div>
    </Style>
  )
}

const Style = Styled.div`
  label: ContactSection;
  position: relative;
  display: flex;
  /* border-left: 10px solid #344465; */
  font-weight: normal;
  justify-content: center;
  color: #fff;
  background: #344465;
  overflow: hidden;

  .tel {
    color: #fff;
  }

  img {
    display: inline-block;
  }

  .footer-left, .footer-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;

    img {
      width: 100%;
      display: block;
    }
  }

  .footer-left {
    left: 0px;
  }

  .footer-right {
    right: 0px;
  }
  
  .wrapper {
    position: relative;
    z-index: 2;
    padding: 100px 40px;
  }

  .box-contact-info {
    position: relative;
    padding: 40px;
    margin: 0 auto;
    text-align: center;
  }

  .address {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .title {
    margin-top: 10px;
    font-size: 20px;
    font-weight: normal;
  }

  .project-item {
    margin-top: 10px;
  }

  ${breakpoint('T')} {
    border-left: none;

    .bg {
      display: none;
    }

    .wrapper {
      width: 100%;
      text-align: center;
      padding: 80px;

      img {
        display: inline-block;
      }
    }

    .footer-left, .footer-right {
      width: 150px;
    }

    .box-contact-info {
      padding: 40px 20px;
    }

    .social-media {
      display: block;
      justify-content: center;
    }
  }

  ${breakpoint('M')} {
    .wrapper {
      padding: 80px 40px;
    }
    
  }

  ${breakpoint('560px')} {
    .wrapper {
      padding: 40px 20px;
    }

    .footer-left {
      left: -100px;
    }
    .footer-right {
      right: -100px;
    }
  }

`
