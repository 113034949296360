import React, { createContext, useState } from 'react'
import en from './en.json'
import th from './th.json'

export const dictionaryList = { en, th }

export const languageOptions = {
  en: 'ENG',
  th: 'THAI'
}

export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en
})

export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState('en')

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'en'
      setUserLanguage(newLanguage)
      window.localStorage.setItem('rcml-lang', newLanguage)
      document.documentElement.setAttribute('lang', selected)
    }
  }

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>
}
