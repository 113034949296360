import React, { createContext, useState } from 'react'

const intitialAppContext = {
  showForm: false
}

export const AppContext = createContext(intitialAppContext)

export function AppContextProvider({ children }) {
  const [appContext, setAppContext] = useState(intitialAppContext)

  const provider = {
    ...appContext,
    set: ({ key, value }) => {
      setAppContext({ ...appContext, [key]: value })
    }
  }

  return <AppContext.Provider value={provider}>{children}</AppContext.Provider>
}
