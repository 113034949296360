import React from 'react'
import Styled from '@emotion/styled'

import Navigation from '../Navigation'
import SocialMedia from '../SocialMedia'
import breakpoint from '../../utils/breakpoint'
import homeBannerMobile from '../../images/home-mobile.jpg'
import logo from '../../images/logo.png'
import people from '../../images/people.png'

import Text from '../Text'

export default () => {
  return (
    <Style>
      <Navigation />
      <div className='wrap-banner'>
        <SocialMedia />
        <div className='box-logo'>
          <img src={logo} alt='' />
          <div className='text'>
            <Text tid='title' />
          </div>
        </div>
        <div className='caption'>
          <h1>
            {/* <span>LIFE DESIGN AT</span> <br /> */}
            “Happiness is all around”
          </h1>
          <p>
            <Text tid='homeDescription' />
          </p>
        </div>
        <div className='box-banner'></div>
      </div>
    </Style>
  )
}

const Style = Styled.div`
  label: Home;
  display: flex;
  border-left: 10px solid #344465;

  .social-media {
    position: relative;
    z-index: 1;
    color: #fff;
    
    .social-media-link {
      color: #fff;
    }
  }

  .wrap-banner {
    flex-grow: 1;
    position: relative;
    height: 100vh;
    overflow: hidden;
    border-bottom-left-radius: 15px;
  }

  .box-banner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    border-bottom-left-radius: 15px;
    background: #353d47 url(${homeBannerMobile}) top center no-repeat;
    background-size: cover;
    animation: zoom 10s linear forwards;

    &::before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: '';
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(84, 84, 84, 0));
    }
  }

  .caption {
    position: absolute;
    z-index: 1;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    text-shadow: 3px 3px 6px #000000;
    font-size: 16px;
    width: 520px;

    h1 {
      font-size: 40px;
      font-family: "SukhumvitSet";
      text-transform: uppercase;
      color: #e7cb71;

      > span {
        font-size: 22px;
        color: #fff;
      }
    }
  }

  .box-logo {
    position: relative;
    z-index: 1;
    margin: 90px auto 0;

    > img {
      display: block;
      margin: 0 auto;
      height: 65px;
    }

    > .text {
      text-align: center;
      text-shadow: 3px 3px 6px #000000;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
    }
  }

  ${breakpoint('725px')} {
    border-left: 0;

    .social-label {
      display: none !important;
    }

    /* .box-banner {
      background: #353d47 url(${homeBannerMobile}) top left no-repeat;
      background-size: cover;
      animation: slide 5s 1s linear forwards;

      &::after {
        position: absolute;
        z-index: 0;
        bottom: 60px;
        left: -16px;
        right: 0;
        top: 50%;
        display: block;
        content: '';
        background: transparent url(${people}) top center no-repeat;
        background-size: cover;
      }
    } */
  }

  ${breakpoint('540px')} {
    .caption {
      font-size: 14px;
      width: 280px;
      bottom: 150px;

      h1 {
        font-size: 30px;
        font-family: "SukhumvitSet";

        > span {
          font-size: 18px;
        }
      }
    }
  }

  @keyframes slide {
    from {
      background-position: 20% 0%
    }
    to { 
      background-position: 60% 0%
    }
  }

  @keyframes zoom {
    from {
      transform: scale(1.3);
    }
    to { 
      transform: scale(1);
    }
  }
`
