import React from 'react'
import { Global } from '@emotion/core'

import { AppContextProvider } from './appContext'
import { LanguageProvider } from './languages'
import globalStyles from './globalStyles'
import Home from './components/Home'
import AboutUsSection from './components/AboutUsSection'
import ProjectSection from './components/ProjectSection'
import ContactSection from './components/ContactSection'
import WeBuyLandForm from './components/WeBuyLandForm'

function App() {
  return (
    <AppContextProvider>
      <LanguageProvider>
        <Global styles={globalStyles} />
        <Home />
        <AboutUsSection />
        <ProjectSection />
        <ContactSection />
        <WeBuyLandForm />
      </LanguageProvider>
    </AppContextProvider>
  )
}

export default App
